@charset "utf-8";

/* ----------------------------------------
 Base
---------------------------------------- */
html {
  font-family: $font-family-base;
  font-size: 62.5%;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body{
  background: $c-white;
  color: $c-black;
  width: 100%;;
  margin: 0 auto;
  font-size: $font-size-pc;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: $base-line-height;
  letter-spacing: $base-letter-spacing;
  @include tab {
    font-size: $font-size-sp;
  }
}
* {
  margin: 0;
  padding: 0;
  font-style: normal;
  list-style: none;
  box-sizing: border-box;
}
a {
  color: $c-gray;
  text-decoration: none;
  border: none;
  outline: none;
  &:hover {
    text-decoration: none;
    img {
      opacity: 0.8;
    }
  }
  img {
    transition: all 0.2s;
  }
}
a:focus, *:focus { outline:none; }
br {line-height: 1;}
img {vertical-align: top;}
input[type="button"],input[type="submit"], select,button {
  cursor: pointer;
}
::-ms-expand { display: none;}
body.ios a {
  img {opacity: 1;}
}
/* IE8+, Chrome */
input, button {
  outline: 0;
}
/* Firefox */
input::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
}

// smartphone input radius riset
input[type="button"],input[type="submit"],input[type="text"],input[type="email"], select,button,textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
}
input::-webkit-input-placeholder,
input:-ms-input-placeholder,
input::-moz-placeholder {
  color: #aaa;
  font-weight: normal;
}

/*clearfix --------------------------- */
.clearfix:after {content: " "; display: block; height: 0; visibility: hidden; clear: both;}
.clearfix {zoom:1;}

.cl {clear: both;}
.fl {float: left;}
.fr {float: right;}

.pc{ display: block;}
.sp{ display: none;}
.pc-in{ display: inline-block;}
.sp-in{ display: none;}
.pc-table{ display: table;}
.sp-table{ display: none;}

@media only screen and (max-width: $pc-narrow) {

  body {
    min-width: inherit;
  }
  img {
    width: 100%;
  }
  a {
    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  // iPhone タップ時拡大防止
  body.ios input[type="text"],
  body.ios select,
  body.ios textarea {
    font-size: 16px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 0;
  }

  .pc{display: none;}
  .sp{display: block;}
  .pc-in{display: none;}
  .sp-in{display: inline-block;}
  .pc-table{ display: none;}
  .sp-table{ display: table;}
}
