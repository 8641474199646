@charset "UTF-8";
/* ----------------------------------------
 Reset
---------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&family=Kosugi&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/* ----------------------------------------
 Base
---------------------------------------- */
html {
  font-family: "Kosugi", sans-serif;
  font-size: 62.5%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  color: #000;
  width: 100%;
  margin: 0 auto;
  font-size: 1.4rem;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.75;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 1.1rem;
  }
}

* {
  margin: 0;
  padding: 0;
  font-style: normal;
  list-style: none;
  box-sizing: border-box;
}

a {
  color: #666;
  text-decoration: none;
  border: none;
  outline: none;
}

a:hover {
  text-decoration: none;
}

a:hover img {
  opacity: 0.8;
}

a img {
  transition: all 0.2s;
}

a:focus, *:focus {
  outline: none;
}

br {
  line-height: 1;
}

img {
  vertical-align: top;
}

input[type="button"], input[type="submit"], select, button {
  cursor: pointer;
}

::-ms-expand {
  display: none;
}

body.ios a img {
  opacity: 1;
}

/* IE8+, Chrome */
input, button {
  outline: 0;
}

/* Firefox */
input::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
}

input[type="button"], input[type="submit"], input[type="text"], input[type="email"], select, button, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
}

input::-webkit-input-placeholder,
input:-ms-input-placeholder,
input::-moz-placeholder {
  color: #aaa;
  font-weight: normal;
}

/*clearfix --------------------------- */
.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.cl {
  clear: both;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.pc {
  display: block;
}

.sp {
  display: none;
}

.pc-in {
  display: inline-block;
}

.sp-in {
  display: none;
}

.pc-table {
  display: table;
}

.sp-table {
  display: none;
}

@media only screen and (max-width: 960px) {
  body {
    min-width: inherit;
  }
  img {
    width: 100%;
  }
  a:hover img {
    opacity: 1;
  }
  body.ios input[type="text"],
  body.ios select,
  body.ios textarea {
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
  .pc-in {
    display: none;
  }
  .sp-in {
    display: inline-block;
  }
  .pc-table {
    display: none;
  }
  .sp-table {
    display: table;
  }
}

/* ========================================
 Common Setting
======================================== */
body.is-block {
  overflow: hidden;
  height: 100%;
}

.inner {
  width: 100%;
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 58px;
  padding-right: 58px;
  position: relative;
  transition: all ease-in-out 0.3s;
}

@media only screen and (max-width: 1250px) {
  .inner {
    max-width: 1250px;
  }
}

@media only screen and (max-width: 960px) {
  .inner {
    max-width: 960px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .inner {
    max-width: 800px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 800px) {
  .inner-sp {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.contents {
  padding-top: 112px;
}

.contents.is-under {
  padding-top: 172px;
}

.contents .marker {
  display: inline;
  background: linear-gradient(transparent 50%, #afe3c8 50%, #afe3c8 100%, transparent 100%);
  padding-bottom: 2px;
}

.contents .small {
  font-size: 80%;
}

@media only screen and (max-width: 960px) {
  .contents {
    padding-top: 68px;
  }
  .contents.is-under {
    padding-top: 112px;
  }
}

.contents .c-h2 {
  margin-bottom: 50px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  text-align: center;
  line-height: 1;
  position: relative;
  padding-bottom: 12px;
}

.contents .c-h2::after {
  content: "";
  background: #000;
  width: 400px;
  height: 1px;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -200px;
}

@media only screen and (max-width: 800px) {
  .contents .c-h2 {
    font-size: 2rem;
  }
  .contents .c-h2::after {
    width: 225px;
    margin-left: -112px;
  }
}

.contents .c-h3 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 80px;
  font-size: 3rem;
}

@media only screen and (max-width: 800px) {
  .contents .c-h3 {
    margin-bottom: 40px;
    font-size: 2rem;
    text-align: center;
  }
}

.base-text, .news-block__content p {
  letter-spacing: normal;
  font-size: 1.6rem;
  line-height: 2.5;
}

@media only screen and (max-width: 800px) {
  .base-text, .news-block__content p {
    font-size: 1.1rem;
  }
}

#pagetop {
  position: relative;
  height: 60px;
}

#pagetop.is-fixed a {
  position: fixed;
  right: 30px;
  bottom: 0;
}

#pagetop a {
  display: block;
  position: absolute;
  right: 30px;
  bottom: 0;
  margin-bottom: 30px;
  transform: translate3d(0, 0, 0);
}

#pagetop a:hover img {
  opacity: 0.8;
}

#pagetop a img {
  transition: all 0.2s;
}

.ancher-btns {
  text-align: center;
  margin-bottom: 80px;
}

.ancher-btns li {
  display: inline-block;
  margin: 0 20px 10px;
}

.ancher-btns li a {
  color: #000;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0;
  text-decoration: underline;
}

@media only screen and (min-width: 961px) {
  body.is-pc .ancher-btns li a:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 800px) {
  .ancher-btns {
    margin-bottom: 40px;
  }
  .ancher-btns li {
    margin: 0 15px 10px;
  }
  .ancher-btns li a {
    font-size: 1.1rem;
  }
}

/* ----------------------------------------
 Header
---------------------------------------- */
header {
  width: 100%;
  height: 112px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  transition: all ease-in-out .3s;
}

@media only screen and (max-width: 960px) {
  header {
    position: absolute;
    height: 0px;
  }
}

header::before {
  content: "";
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

@media only screen and (max-width: 960px) {
  header::before {
    display: none;
  }
}

body.is-scrolled.is-pc header {
  height: 86px;
}

header .logo {
  position: absolute;
  left: 58px;
  top: 48px;
  transition: left ease-in-out 0.3s;
}

@media only screen and (max-width: 960px) {
  header .logo {
    top: 10px;
    left: 10px;
  }
}

header .logo .design svg {
  fill: #666;
}

@media only screen and (max-width: 960px) {
  header .logo .design {
    width: 70px;
  }
  header .logo .design svg {
    width: 100%;
    height: 79px;
  }
  header .logo .design svg {
    fill: #666;
  }
}

body.is-scrolled.is-pc header .logo .typo li {
  opacity: 1;
  transform: translateX(0px);
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(1) {
  transition-delay: 0.02s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(2) {
  transition-delay: 0.04s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(3) {
  transition-delay: 0.06s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(4) {
  transition-delay: 0.08s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(5) {
  transition-delay: 0.1s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(6) {
  transition-delay: 0.12s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(7) {
  transition-delay: 0.14s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(8) {
  transition-delay: 0.16s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(9) {
  transition-delay: 0.18s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(10) {
  transition-delay: 0.2s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(11) {
  transition-delay: 0.22s;
}

body.is-scrolled.is-pc header .logo .typo li:nth-child(12) {
  transition-delay: 0.24s;
}

header .logo .typo {
  display: flex;
  color: #666;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 1;
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 801px) and (max-width: 1080px) {
  header .logo .typo {
    font-size: 2rem;
  }
}

header .logo .typo li {
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out .2s;
  transform: translateX(-10px);
}

header .logo .typo li:nth-child(6) {
  margin-right: 0.5em;
}

@media only screen and (max-width: 960px) {
  header .logo .typo {
    display: none;
  }
}

header .nav .nav-wrap {
  position: absolute;
  right: 58px;
  top: 50px;
}

@media only screen and (max-width: 960px) {
  header .nav .nav-wrap {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    right: auto;
    left: 0;
    top: 0;
    z-index: 8888;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

header .nav-main {
  display: flex;
  transition: all ease-in-out 0.3s;
}

body.is-scrolled.is-pc header .nav-main {
  top: 35px;
}

@media only screen and (max-width: 960px) {
  header .nav-main {
    display: block;
    width: 100%;
    height: auto;
    transition: none;
    padding-bottom: 68px;
  }
  header .nav-main::before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

header .nav-main li {
  margin-left: 50px;
  position: relative;
}

header .nav-main li:first-child a {
  border-top: none;
}

header .nav-main li .new {
  position: absolute;
  right: -20px;
  top: -20px;
  z-index: 1;
  animation-duration: 0.5s;
  animation-name: fade-in;
}

header .nav-main li .new img {
  width: 29.95px;
  height: 31.2px;
  animation: spin 4s linear infinite;
}

@media only screen and (max-width: 960px) {
  header .nav-main li .new {
    left: -40px;
    right: auto;
    top: 50%;
    margin-top: -16px;
  }
}

header .nav-main li:first-child {
  margin-left: 0;
}

@media only screen and (min-width: 961px) {
  body.is-pc header .nav-main li:hover.has-subnav > a + .sub-nav {
    display: block;
  }
  body.is-pc header .nav-main li:hover.has-subnav > a + .sub-nav a {
    pointer-events: all;
  }
  body.is-pc header .nav-main li:hover > a {
    color: #000;
  }
  body.is-pc header .nav-main li:hover > a:after {
    transform: scaleX(1);
  }
}

@media only screen and (max-width: 960px) {
  header .nav-main li.has-subnav a.pc-in + .sub-nav dd:first-child {
    border-top: none;
  }
}

@media only screen and (max-width: 960px) {
  header .nav-main li {
    margin-left: 0;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #fff;
  }
}

header .nav-main li a {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 1;
  color: #666;
  transition: color ease-in-out 0.3s;
  position: relative;
}

header .nav-main li a:after {
  content: "";
  background: #000;
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  top: 1em;
  transform: scaleX(0);
  transform-origin: left;
  transition: all ease-in-out 0.15s;
}

header .nav-main li a.active {
  color: #000;
}

header .nav-main li a.active:after {
  transform: scaleX(1);
}

@media only screen and (min-width: 961px) {
  body.is-pc header .nav-main li a:hover {
    color: #000;
  }
  body.is-pc header .nav-main li a:hover:after {
    transform: scaleX(1);
  }
}

@media only screen and (max-width: 960px) {
  header .nav-main li a {
    display: block;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 2rem;
    padding: 25px 0 23px;
  }
  header .nav-main li a.pc-in {
    display: none;
  }
  header .nav-main li a.active {
    color: #fff;
  }
  header .nav-main li a.active:after {
    display: none;
  }
}

header .nav-main li a .in {
  display: inline-block;
  position: relative;
}

header .nav-main li .sub-nav {
  display: none;
  padding-top: 5px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 130px;
  animation-duration: 0.5s;
  animation-name: fade-in;
}

header .nav-main li .sub-nav dd {
  margin-top: 10px;
}

header .nav-main li .sub-nav a {
  display: inline-block;
  position: relative;
  font-size: 2rem;
  pointer-events: none;
}

header .nav-main li .sub-nav a::before {
  content: ">";
  display: inline-block;
  margin-right: 0.25em;
}

header .nav-main li .sub-nav a::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  bottom: 3px;
  transform: scaleX(0);
  transform-origin: left;
  transition: all ease-in-out 0.15s;
}

header .nav-main li .sub-nav a div {
  display: inline-block;
}

header .nav-main li .sub-nav a .jp {
  font-size: 1.5rem;
}

@media only screen and (max-width: 960px) {
  header .nav-main li .sub-nav {
    display: block;
    position: static;
    width: 100%;
    padding-top: 0px;
  }
  header .nav-main li .sub-nav dd {
    margin-top: 0;
  }
  header .nav-main li .sub-nav dd:first-child a {
    border-top: none;
  }
  header .nav-main li .sub-nav a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    padding: 25px 0;
    border-top: 1px solid #fff;
    pointer-events: visible;
  }
  header .nav-main li .sub-nav a::before {
    display: none;
  }
  header .nav-main li .sub-nav a div {
    display: flex;
    align-items: center;
  }
  header .nav-main li .sub-nav a .jp {
    display: inline-block;
    padding-left: 0.5em;
    padding-bottom: 2px;
    font-size: 1rem;
  }
}

header .nav-btn {
  width: 33px;
  height: 26px;
  position: fixed;
  right: 20px;
  top: 21px;
  z-index: 9999;
  cursor: pointer;
}

body.is-block header .nav-btn span {
  opacity: 0;
  transform: translateX(5px);
}

body.is-block header .nav-btn::before, body.is-block header .nav-btn::after {
  background: #fff;
  width: 36px;
}

body.is-block header .nav-btn::before {
  transform: translateY(12px) rotate(135deg);
}

body.is-block header .nav-btn::after {
  transform: translateY(-12px) rotate(-135deg);
}

header .nav-btn::before, header .nav-btn::after,
header .nav-btn span {
  position: relative;
  transition: all ease-in-out 0.3s;
}

header .nav-btn span {
  background: #666;
  height: 2px;
  width: 33px;
  opacity: 1;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
}

header .nav-btn::before, header .nav-btn::after {
  content: "";
  display: block;
  background: #666;
  height: 2px;
  width: 33px;
  position: absolute;
  left: 0;
}

header .nav-btn::before {
  top: 0;
}

header .nav-btn::after {
  bottom: 0;
}

/* ----------------------------------------
 Footer
---------------------------------------- */
footer {
  text-align: center;
}

footer .copy {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  padding: 60px 20px;
}

@media only screen and (max-width: 800px) {
  footer .copy {
    padding: 30px 15px;
  }
}

/* ----------------------------------------
 Loading
---------------------------------------- */
.loading {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
}

.loading-bar {
  display: none;
  background: #fff;
  width: 100%;
  height: 130px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@media only screen and (max-width: 800px) {
  .loading-bar {
    height: 70px;
  }
}

.loading-bar .sk-fading-circle {
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.sk-fading-circle {
  margin: auto;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #14b480;
  border-radius: 100%;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/* ========================================
 Index Setting
======================================== */
.mainvisual {
  position: relative;
}

.mainvisual .slider .slide figure {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 625px;
  position: relative;
}

@media only screen and (max-width: 800px) {
  .mainvisual .slider .slide figure {
    height: 375px;
  }
}

.mainvisual .scroll {
  position: absolute;
  left: 50%;
  bottom: 20px;
  margin-left: -30px;
  z-index: 1;
  animation: flash 2s linear infinite;
  cursor: pointer;
}

.mainvisual .scroll svg {
  fill: #fff;
}

@media only screen and (max-width: 800px) {
  .mainvisual .scroll {
    position: relative;
    top: 10px;
    bottom: auto;
    margin-left: -15px;
  }
  .mainvisual .scroll svg {
    fill: #666;
    width: 29px;
    height: 15px;
  }
}

.news-block {
  margin-top: 100px;
}

.news-block .news-list {
  width: 100%;
  max-width: 840px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.news-block .news-list dl {
  overflow: hidden;
}

.news-block .news-list dl dt {
  float: left;
  width: 130px;
}

.news-block .news-list dl dd {
  overflow: hidden;
  margin-bottom: 12px;
}

.news-block .news-list dl dd a {
  transition: all ease-in-out 0.3s;
}

@media only screen and (min-width: 961px) {
  body.is-pc .news-block .news-list dl dd a:hover {
    opacity: 0.7;
  }
}

.news-block .news-list .btn-area {
  margin-top: 10px;
  text-align: right;
}

.news-block .news-list .btn-area a {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 2.2rem;
}

@media only screen and (max-width: 800px) {
  .news-block {
    margin-top: 60px;
  }
  .news-block .news-list {
    padding-left: 10px;
    padding-right: 10px;
  }
  .news-block .news-list dl dt {
    float: none;
    width: 100%;
  }
  .news-block .news-list .btn-area a {
    font-size: 1.3rem;
  }
}

/* ========================================
 Works Setting
======================================== */
.category {
  color: #000;
  font-size: 1.4rem;
}

.category span {
  display: inline-block;
}

.category span:not(:last-of-type)::after {
  content: ",";
  display: inline;
}

@media only screen and (max-width: 800px) {
  .category {
    font-size: 1rem;
    line-height: 1.4;
  }
}

.works-block {
  margin-top: 86px;
}

.works-block#archive-flyer .works-list {
  margin-top: 80px;
}

@media only screen and (max-width: 800px) {
  .works-block#archive-flyer .works-list {
    margin-top: 40px;
  }
}

.works-block h3 {
  text-align: center;
}

.is-under .works-block {
  margin-top: 0;
}

@media only screen and (max-width: 800px) {
  .works-block {
    margin-top: 20px;
  }
  .works-block .works-list {
    display: block;
  }
}

.is-under .works-block h2 {
  margin-bottom: 30px;
}

.works-block .works-categorys {
  text-align: center;
  margin-bottom: 80px;
}

.works-block .works-categorys li {
  display: inline-block;
  margin: 0 20px 10px;
}

.works-block .works-categorys li a {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0;
}

@media only screen and (min-width: 961px) {
  body.is-pc .works-block .works-categorys li a:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 800px) {
  .works-block .works-categorys {
    margin-bottom: 40px;
  }
  .works-block .works-categorys li {
    margin: 0 15px 10px;
  }
  .works-block .works-categorys li a {
    font-size: 1.1rem;
  }
}

.works-block .works-list {
  display: flex;
  flex-wrap: wrap;
}

.works-block .works-list .ajax-item {
  opacity: 0;
  transition: opacity ease-in-out 0.3s 0.3s;
}

.works-block .works-list .ajax-item.is-show {
  opacity: 1;
}

.works-block .works-list a {
  display: block;
  width: 31.2%;
  margin-left: 3.2%;
  margin-bottom: 30px;
}

@media only screen and (min-width: 961px) {
  body.is-pc .works-block .works-list a:hover .figure-wrap .title {
    opacity: 1;
  }
}

.works-block .works-list a:nth-of-type(3n+1) {
  margin-left: 0;
}

.works-block .works-list a .figure-wrap {
  position: relative;
}

.works-block .works-list a .figure-wrap figure {
  width: 100%;
  padding-top: 66.66%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.works-block .works-list a .figure-wrap figure img {
  width: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.works-block .works-list a .figure-wrap .title {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  width: 100%;
  height: 100%;
  font-size: 2.2rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  transition: all ease-in-out 0.3s;
}

@media only screen and (max-width: 800px) {
  .works-block .works-list a {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .works-block .works-list a:nth-of-type(3n+1) {
    margin-left: 0;
  }
  .works-block .works-list a .figure-wrap .title {
    display: block;
    background: none;
    color: #000;
    margin-top: 5px;
    position: static;
    font-size: 1rem;
    line-height: 1.4;
    opacity: 1;
  }
}

.works-block .works-detail {
  overflow: hidden;
  margin-top: 112px;
}

@media only screen and (max-width: 800px) {
  .works-block .works-detail {
    margin-top: 50px;
  }
}

.works-block .works-detail__info {
  float: left;
  width: 30.4%;
}

@media only screen and (max-width: 800px) {
  .works-block .works-detail__info {
    float: none;
    width: 100%;
  }
  .works-block .works-detail__info .sp-photo img {
    width: 100%;
  }
}

.works-block .works-detail__photo {
  float: right;
  width: 65.92%;
}

.works-block .works-detail__photo li {
  margin-top: 50px;
}

.works-block .works-detail__photo li:first-child {
  margin-top: 0;
}

.works-block .works-detail__photo li img {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .works-block .works-detail__photo {
    float: none;
    width: 100%;
  }
  .works-block .works-detail__photo li {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .works-block .works-detail .detail-content {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 961px) {
  body.is-pc .works-block .works-detail .detail-content a:hover {
    text-decoration: underline;
  }
}

.works-block .works-detail .detail-content__title {
  font-size: 2.2rem;
}

@media only screen and (max-width: 800px) {
  .works-block .works-detail .detail-content__title {
    font-size: 2.1rem;
    text-align: center;
  }
}

.works-block .works-detail .detail-content__main {
  margin-top: 45px;
  letter-spacing: normal;
  font-size: 1.6rem;
  line-height: 2.5;
}

@media only screen and (max-width: 800px) {
  .works-block .works-detail .detail-content__main {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 800px) {
  .works-block .works-detail .detail-content__main {
    margin-top: 20px;
  }
}

.works-block .works-detail .detail-content__info {
  margin-top: 60px;
  padding-top: 10px;
  border-top: 1px solid #000;
}

.works-block .works-detail .detail-content__info dl {
  overflow: hidden;
}

.works-block .works-detail .detail-content__info dl dt {
  float: left;
  width: 80px;
  position: relative;
}

.works-block .works-detail .detail-content__info dl dt::after {
  content: "：";
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.works-block .works-detail .detail-content__info dl dd {
  overflow: hidden;
}

@media only screen and (max-width: 800px) {
  .works-block .works-detail .detail-content__info {
    margin-top: 20px;
  }
}

.works-block .works-nav {
  margin: 110px 0 136px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px 5px 18px;
  overflow: hidden;
  position: relative;
}

.works-block .works-nav.is-flyer {
  width: 100%;
  height: 100px;
  padding: 10px 5px 0;
  border-bottom: none;
}

.works-block .works-nav.is-flyer .btn-all {
  position: absolute;
  left: 50%;
  top: calc(50% + 10px);
  transform: translate3d(-50%, -50%, 0);
  margin: 0;
}

.works-block .works-nav.is-flyer .btn-prev,
.works-block .works-nav.is-flyer .btn-next {
  display: flex;
  align-items: center;
}

.works-block .works-nav.is-flyer .btn-prev figure,
.works-block .works-nav.is-flyer .btn-next figure {
  border: solid 1px #eee;
}

.works-block .works-nav.is-flyer .btn-prev img,
.works-block .works-nav.is-flyer .btn-next img {
  width: 100%;
  max-width: 150px;
}

.works-block .works-nav.is-flyer .btn-prev {
  flex-direction: row-reverse;
}

.works-block .works-nav.is-flyer .btn-prev figure {
  margin-right: 20px;
}

.works-block .works-nav.is-flyer .btn-next figure {
  margin-left: 20px;
}

@media only screen and (max-width: 800px) {
  .works-block .works-nav.is-flyer .btn-all {
    top: 10px;
    transform: translate3d(-50%, 0, 0);
  }
  .works-block .works-nav.is-flyer .btn-prev,
  .works-block .works-nav.is-flyer .btn-next {
    display: block;
  }
  .works-block .works-nav.is-flyer .btn-prev figure,
  .works-block .works-nav.is-flyer .btn-next figure {
    margin: 4px 0 0 0;
  }
  .works-block .works-nav.is-flyer .btn-prev img,
  .works-block .works-nav.is-flyer .btn-next img {
    max-width: 100px;
  }
  .works-block .works-nav.is-flyer .btn-next {
    text-align: right;
  }
}

.works-block .works-nav a {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  line-height: 1;
  font-size: 2.4rem;
}

.works-block .works-nav a.btn-prev {
  float: left;
}

.works-block .works-nav a.btn-next {
  float: right;
}

.works-block .works-nav a.btn-all {
  position: absolute;
  left: 50%;
  margin-left: -1.5em;
}

@media only screen and (max-width: 800px) {
  .works-block .works-nav {
    margin: 100px 0;
  }
  .works-block .works-nav a {
    font-size: 1.5rem;
  }
}

.works-block .btn-area {
  margin-top: 60px;
  text-align: center;
}

.works-block .btn-area li:not(:first-child) {
  margin-top: 50px;
}

.works-block .btn-area .btn-works {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px #000;
  position: relative;
}

.works-block .btn-area .btn-works::before {
  content: "";
  background: #afe3c8;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  animation: flash 3s linear infinite;
}

.works-block .btn-area .btn-works span {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  z-index: 2;
}

.works-block .btn-area .btn-flyer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px #000;
  position: relative;
}

.works-block .btn-area .btn-flyer span {
  display: inline-block;
  padding-top: 2px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 2.4rem;
  z-index: 2;
}

@media only screen and (max-width: 800px) {
  .works-block .btn-area li:not(:first-child) {
    margin-top: 30px;
  }
  .works-block .btn-area .btn-works {
    width: 225px;
    height: 52px;
  }
  .works-block .btn-area .btn-works span {
    font-size: 2rem;
  }
  .works-block .btn-area .btn-flyer {
    width: 100%;
    max-width: 342px;
    height: 52px;
  }
  .works-block .btn-area .btn-flyer span {
    font-size: 2rem;
  }
}

/* ========================================
 About Setting
======================================== */
.is-under .about-block h2 {
  margin-bottom: 30px;
}

.about-block .about-contents {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents {
    padding: 0 10px;
  }
}

.about-block .about-contents h3 {
  line-height: 1;
  margin-bottom: 30px;
}

.about-block .about-contents__block {
  padding-bottom: 80px;
}

.about-block .about-contents__block-in {
  width: 100%;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block {
    padding-bottom: 40px;
  }
}

.about-block .about-contents__block .title {
  margin-bottom: 30px;
  font-size: 2.8rem;
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block .title {
    margin-bottom: 20px;
    font-size: 2.1rem;
    line-height: 1.38;
  }
}

.about-block .about-contents__block .photo-set {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 120px;
}

.about-block .about-contents__block .photo-set li {
  width: 100%;
  max-width: 300px;
  margin: 0 14px;
}

.about-block .about-contents__block .photo-set li img {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block .photo-set {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .about-block .about-contents__block .photo-set li {
    width: 49%;
    max-width: none;
    margin: 0 0 0 2%;
  }
  .about-block .about-contents__block .photo-set li:first-child {
    margin-left: 0;
  }
}

.about-block .about-contents__block .about-information {
  margin-top: 80px;
}

.about-block .about-contents__block .about-information dl {
  overflow: hidden;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
}

.about-block .about-contents__block .about-information dl dt {
  float: left;
  width: 85px;
  position: relative;
}

.about-block .about-contents__block .about-information dl dt::after {
  content: "：";
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.about-block .about-contents__block .about-information dl dd {
  margin-bottom: 10px;
  overflow: hidden;
}

.about-block .about-contents__block .about-information dl dd .note {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 1.2rem;
  line-height: 1.5;
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block .about-information {
    margin-top: 20px;
  }
  .about-block .about-contents__block .about-information dl {
    font-size: 1.1rem;
  }
  .about-block .about-contents__block .about-information dl dt {
    width: 60px;
  }
  .about-block .about-contents__block .about-information dl dd {
    margin-bottom: 20px;
  }
  .about-block .about-contents__block .about-information dl dd .note {
    padding-top: 5px;
    margin-bottom: 5px;
    font-size: 1rem;
  }
}

.about-block .about-contents__block .map-wrap {
  margin-top: 40px;
}

.about-block .about-contents__block .map-wrap p {
  font-size: 1.6rem;
}

.about-block .about-contents__block .map-wrap p .note {
  font-size: 1.2rem;
}

.about-block .about-contents__block .map-wrap p .note a {
  text-decoration: underline;
}

.about-block .about-contents__block .map-wrap iframe {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block .map-wrap {
    margin-top: 20px;
  }
  .about-block .about-contents__block .map-wrap p {
    font-size: 1.1rem;
  }
  .about-block .about-contents__block .map-wrap p .note {
    font-size: 1rem;
  }
  .about-block .about-contents__block .map-wrap iframe {
    height: 224px;
  }
}

.about-block .about-contents__block .profiles {
  margin-bottom: 50px;
}

.about-block .about-contents__block .profiles li {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.about-block .about-contents__block .profiles li:first-child {
  margin-top: 0;
}

@media only screen and (min-width: 961px) {
  body.is-pc .about-block .about-contents__block .profiles li.photo-change:hover .profiles__photo .on {
    opacity: 1;
  }
  body.is-pc .about-block .about-contents__block .profiles li.photo-change:hover .profiles__photo .off {
    opacity: 0;
  }
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block .profiles {
    margin-top: 30px;
  }
  .about-block .about-contents__block .profiles li {
    display: block;
  }
}

.about-block .about-contents__block .profiles__photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  min-height: 150px;
  position: relative;
  text-align: center;
}

.about-block .about-contents__block .profiles__photo .on, .about-block .about-contents__block .profiles__photo .off {
  transition: all ease-in-out 0.3s;
}

.about-block .about-contents__block .profiles__photo .on {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.about-block .about-contents__block .profiles__photo .illust1 {
  max-width: 73px;
}

.about-block .about-contents__block .profiles__photo .illust2 {
  max-width: 92px;
}

.about-block .about-contents__block .profiles__photo .illust3 {
  max-width: 76px;
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block .profiles__photo {
    width: 100%;
    min-height: 75px;
  }
  .about-block .about-contents__block .profiles__photo.is-view .on {
    opacity: 1;
  }
  .about-block .about-contents__block .profiles__photo.is-view .off {
    opacity: 0;
  }
  .about-block .about-contents__block .profiles__photo .on {
    max-width: 75px;
  }
  .about-block .about-contents__block .profiles__photo .illust1 {
    max-width: 39px;
  }
  .about-block .about-contents__block .profiles__photo .illust2 {
    max-width: 56px;
  }
  .about-block .about-contents__block .profiles__photo .illust3 {
    max-width: 38px;
  }
}

.about-block .about-contents__block .profiles__info {
  width: calc(100% - 180px);
}

.about-block .about-contents__block .profiles__info .degree {
  font-size: 1.6rem;
}

.about-block .about-contents__block .profiles__info .name {
  font-size: 1.6rem;
}

.about-block .about-contents__block .profiles__info .name .en {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
}

.about-block .about-contents__block .profiles__info .profile {
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block .profiles__info {
    width: 100%;
    margin-top: 20px;
  }
  .about-block .about-contents__block .profiles__info .degree {
    font-size: 1.5rem;
    text-align: center;
  }
  .about-block .about-contents__block .profiles__info .name {
    font-size: 1.5rem;
    text-align: center;
  }
  .about-block .about-contents__block .profiles__info .profile {
    margin-top: 10px;
    font-size: 1.1rem;
  }
}

.about-block .about-contents__block .memo-btns {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.about-block .about-contents__block .memo-btns a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  width: 300px;
  height: 100px;
  margin: 0 25px;
  border: solid 1px #000;
  font-size: 2rem;
  transition: all ease-in-out 0.3s;
}

@media only screen and (min-width: 961px) {
  body.is-pc .about-block .about-contents__block .memo-btns a:hover {
    background: #afe3c8;
  }
}

@media only screen and (max-width: 800px) {
  .about-block .about-contents__block .memo-btns {
    display: block;
  }
  .about-block .about-contents__block .memo-btns a {
    width: 225px;
    height: 54px;
    margin: 26px auto 0;
    font-size: 1.5rem;
  }
}

/* ========================================
 Memo Setting
======================================== */
.memo-block {
  margin-bottom: 80px;
}

@media only screen and (max-width: 800px) {
  .memo-block {
    margin-bottom: 40px;
  }
}

.memo-block .memo-contents {
  width: 100%;
  max-width: 836px;
  margin-left: auto;
  margin-right: auto;
}

.memo-block .memo-contents h3 {
  font-size: 2.8rem;
  margin-bottom: 0;
  padding: 0 43px;
}

.memo-block .memo-contents h4 {
  font-size: 2.8rem;
  margin-bottom: 15px;
  line-height: 1.5;
}

@media only screen and (max-width: 800px) {
  .memo-block .memo-contents h3 {
    font-size: 1.8rem;
  }
  .memo-block .memo-contents h4 {
    font-size: 2.1rem;
    line-height: 1.38;
  }
}

.memo-block .memo-contents__block {
  margin-top: 40px;
}

.memo-block .memo-contents__block#life {
  margin-top: 100px;
}

@media only screen and (max-width: 800px) {
  .memo-block .memo-contents__block {
    margin-top: 20px;
    padding-top: 20px;
  }
  .memo-block .memo-contents__block#life {
    margin-top: 80px;
  }
}

.memo-block .memo-contents .acc-content {
  background: #f6f6f6;
  margin-top: 58px;
  padding: 30px 43px 50px;
  position: relative;
}

@media only screen and (max-width: 800px) {
  .memo-block .memo-contents .acc-content {
    margin-top: 36px;
    padding: 17px;
  }
}

.memo-block .memo-contents .acc-content h4 {
  margin-top: 0;
}

@media only screen and (max-width: 800px) {
  .memo-block .memo-contents .acc-content h4 {
    letter-spacing: 0;
  }
}

.memo-block .memo-contents .acc-content .acc-main {
  position: relative;
  overflow: hidden;
  height: 8rem;
  text-align: justify;
  text-justify: inter-ideograph;
  font-size: 1.6rem;
}

@media only screen and (max-width: 800px) {
  .memo-block .memo-contents .acc-content .acc-main {
    font-size: 1.1rem;
  }
}

.memo-block .memo-contents .acc-content .acc-main::after {
  content: "...続きを読む";
  background: #f6f6f6;
  color: #808080;
  line-height: 1;
  padding-left: 0.5em;
  position: absolute;
  right: 0;
  bottom: 0.75em;
  transition: all ease-in-out 0.3s;
}

.memo-block .memo-contents .acc-content .acc-main.is-open::after {
  opacity: 0;
}

@media only screen and (max-width: 800px) {
  .memo-block .memo-contents .acc-content .acc-main {
    height: 5.5rem;
  }
}

.memo-block .memo-contents .acc-content .btn-open {
  background: #fff;
  border-radius: 5px;
  width: 52px;
  height: 60px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  padding-top: 42px;
  position: absolute;
  left: 50%;
  bottom: -40px;
  z-index: 1;
  margin-left: -26px;
  cursor: pointer;
}

.memo-block .memo-contents .acc-content .btn-open::before, .memo-block .memo-contents .acc-content .btn-open::after {
  content: "";
  background: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.memo-block .memo-contents .acc-content .btn-open::before {
  width: 28px;
  height: 2px;
  top: 23px;
}

.memo-block .memo-contents .acc-content .btn-open::after {
  width: 2px;
  height: 28px;
  top: 10px;
}

.memo-block .memo-contents .acc-content .btn-open.is-open::after {
  display: none;
}

.memo-block .memo-contents .acc-content .btn-open.is-open .open {
  display: none;
}

.memo-block .memo-contents .acc-content .btn-open.is-open .close {
  display: block;
}

.memo-block .memo-contents .acc-content .btn-open .close {
  display: none;
}

@media only screen and (max-width: 800px) {
  .memo-block .memo-contents .acc-content .btn-open {
    transform: scale(0.5) translateY(-20%);
  }
  .memo-block .memo-contents .acc-content .btn-open::before {
    width: 26px;
    top: 22px;
  }
  .memo-block .memo-contents .acc-content .btn-open::after {
    width: 2px;
    height: 26px;
    top: 10px;
  }
}

.memo-block .memo-contents .life-archives {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.memo-block .memo-contents .life-archives .life-archive {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  color: #000;
}

.memo-block .memo-contents .life-archives .life-archive__photo {
  width: 32%;
}

.memo-block .memo-contents .life-archives .life-archive__photo img {
  width: 100%;
}

.memo-block .memo-contents .life-archives .life-archive__text {
  width: 65.33%;
}

.memo-block .memo-contents .life-archives .life-archive__text h4 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .memo-block .memo-contents .life-archives .life-archive {
    margin-top: 20px;
  }
  .memo-block .memo-contents .life-archives .life-archive h4 {
    margin-bottom: 10px;
  }
  .memo-block .memo-contents .life-archives .life-archive p {
    font-size: 1.1rem;
  }
}

/* ========================================
 News Setting
======================================== */
.pagination {
  margin-top: 50px;
  text-align: center;
}

.pagination a, .pagination span {
  display: inline-block;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 2rem;
  padding: 6px 8px;
}

.pagination a {
  color: #808080;
  text-decoration: underline;
}

.pagination a.prev, .pagination a.next {
  text-decoration: none;
}

.news-block {
  width: 100%;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.is-under .news-block {
  margin-top: 40px;
}

@media only screen and (max-width: 800px) {
  .news-block {
    padding: 0 10px;
  }
}

.news-block__thumbnail {
  margin-bottom: 20px;
  text-align: center;
}

.news-block__thumbnail img {
  width: 100%;
  max-width: 750px;
}

.news-block__meta .date {
  font-family: 1.4rem;
}

.news-block__title {
  margin-top: 10px;
  font-size: 2.8rem;
}

@media only screen and (max-width: 800px) {
  .news-block__title {
    font-size: 2.1rem;
  }
}

.news-block__content {
  margin-top: 40px;
  font-size: 1.6rem;
  overflow: hidden;
}

@media only screen and (max-width: 800px) {
  .news-block__content {
    margin-top: 20px;
  }
}

.news-block__content p {
  margin-bottom: 1em;
}

.news-block__content img {
  width: 100%;
  height: auto;
}

.news-block__content .is-postimage {
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 100%;
  height: auto;
}

.news-block__content .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.news-block__content .alignright {
  float: right;
}

.news-block__content .alignleft {
  float: left;
}

/* ---------- Sprite SVG START ---------- */
.svg-logo, .svg-scroll {
  background: url("../img/sprite-bg.svg") no-repeat;
}

.svg-logo {
  background-position: 0 0;
}

.svg-logo_dims {
  width: 142.41px;
  height: 160.94px;
}

.svg-scroll {
  background-position: 99.85275557425325% 0;
}

.svg-scroll_dims {
  width: 60.38px;
  height: 50.31px;
}

/* ---------- Sprite SVG END ---------- */
/* ----------------------------------------
 Animation
---------------------------------------- */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  25% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
}

@keyframes MoveDown {
  0% {
    transform: translateY(0);
  }
  49.9% {
    transform: translateY(10px);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes MoveDownInfi {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  74.9% {
    transform: translateY(10px);
    opacity: 0;
  }
  75% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes MoveUp {
  0% {
    transform: translateY(0);
  }
  49.9% {
    transform: translateY(-10px);
    opacity: 0;
  }
  50% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes MoveRight {
  0% {
    transform: translateX(0);
  }
  49.9% {
    transform: translateX(10px);
    opacity: 0;
  }
  50% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes MoveLeft {
  0% {
    transform: translateX(0);
  }
  49.9% {
    transform: translateX(-10px);
    opacity: 0;
  }
  50% {
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes reveal {
  0% {
    transform-origin: left;
    transform: translateZ(0) skew(-37deg) scaleX(0);
  }
  0.0001% {
    transform-origin: left;
  }
  50% {
    transform-origin: left;
    transform: translateZ(0) skew(-37deg) scaleX(1);
  }
  50.0001% {
    transform-origin: right;
  }
  100% {
    transform-origin: right;
    transform: translateZ(0) skew(-37deg) scaleX(0);
  }
}
