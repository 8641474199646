/* ========================================
 Common Setting
======================================== */

body {
  &.is-block {
    overflow: hidden;
    height: 100%;
  }
}

.inner {
  width: 100%;
  max-width: $pc-wide;
  margin-right: auto;
  margin-left: auto;
  padding-left: 58px;
  padding-right: 58px;
  position: relative;
  transition: all ease-in-out $duration;
  @include pc {
    max-width: $pc-width;
  }
  @include pc-narrow {
    max-width: $pc-narrow;
    padding-left: 30px;
    padding-right: 30px;
  }
  @include tab {
    max-width: $breakpoint;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.inner-sp {
  @include tab {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.contents {
  padding-top: 112px;
  &.is-under {
    padding-top: 172px;
  }
  .marker {
    display: inline;
    background: linear-gradient(transparent 50%, #afe3c8 50%, #afe3c8 100%, transparent 100%);
    padding-bottom: 2px;
  }
  .small {
    font-size: 80%;
  }
  @include pc-narrow {
    padding-top: 68px;
    &.is-under {
      padding-top: 112px;
    }
  }
  .c-h2 {
    margin-bottom: 50px;
    @include en;
    font-size: 3rem;
    text-align: center;
    line-height: 1;
    position: relative;
    padding-bottom: 12px;
    &::after {
      content:"";
      background: #000;
      width: 400px;
      height: 1px;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -200px;
    }
    @include tab {
      font-size: 2rem;
      &::after {
        width: 225px;
        margin-left: -112px;
      }
    }
  }
  .c-h3 {
    @include en;
    margin-bottom: 80px;
    font-size: 3rem;
    @include tab {
      margin-bottom: 40px;
      font-size: 2rem;
      text-align: center;
    }
  }
}
.base-text {
  letter-spacing: normal;
  font-size: 1.6rem;
  line-height: 2.5;
  @include tab {
    font-size: 1.1rem;
  }
}

@mixin honbun{
  letter-spacing: normal;
  font-size: 1.6rem;
  line-height: 2.5;
  @include tab {
    font-size: 1.1rem;
  }
}

#pagetop {
  position: relative;
  height: 60px;
  &.is-fixed a {
    position: fixed;
    right: 30px;
    bottom: 0;
  }
  a {
    display: block;
    position: absolute;
    right: 30px;
    bottom: 0;
    margin-bottom: 30px;
    transform: translate3d(0, 0, 0);
    &:hover img {
      opacity: 0.8;
    }
    img {
      transition: all 0.2s;
    }
  }
}

.ancher-btns {
  text-align: center;
  margin-bottom: 80px;
  li {
    display: inline-block;
    margin: 0 20px 10px;
    a {
      color: #000;
      @include en;
      font-size: 1.6rem;
      letter-spacing: 0;
      text-decoration: underline;
      @include hover {
        text-decoration: underline;
      }
    }
  }
  @include tab {
    margin-bottom: 40px;
    li {
      margin: 0 15px 10px;
      a {
        font-size: 1.1rem;
      }
    }
  }
}

/* ----------------------------------------
 Header
---------------------------------------- */
header {
  width: 100%;
  height: 112px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  transition: all ease-in-out .3s;
  @include pc-narrow {
    position: absolute;
    height: 0px;
  }
  &::before {
    content:"";
    background: rgba(#fff,0.85);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    @include pc-narrow {
      display: none;
    }
  }
  body.is-scrolled.is-pc & {
    height: 86px;
  }
  .logo {
    position: absolute;
    left: 58px;
    top: 48px;
    transition: left ease-in-out $duration;
    @include pc-narrow {
      top: 10px;
      left: 10px;
    }
    .design {
      // transition: all ease-in-out $duration;
      svg {
        fill: $c-gray;
      }
      @include pc-narrow {
        width: 70px;
        svg {
          width: 100%;
          height: 79px;
        }
        svg {
          fill: $c-gray;
        }
      }
    }
    body.is-scrolled.is-pc & {
      .typo {
        li {
          opacity: 1;
          transform: translateX(0px);
          @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
              transition-delay: $i * 0.02s;
            }
          }
        }
      }
    }
    .typo {
      display: flex;
      color: $c-gray;
      @include en;
      font-size: 3rem;
      line-height: 1;
      position: absolute;
      left: 0;
      top: 0;
      @media only screen and (min-width: 801px) and (max-width: 1080px) {
        font-size: 2rem;
      }
      li {
        opacity: 0;
        pointer-events: none;
        transition: all ease-in-out .2s;
        transform: translateX(-10px);
        &:nth-child(6) {
          margin-right: 0.5em;
        }
      }
      @include pc-narrow {
        display: none;
      }
    }
  }
  .nav {
    .nav-wrap {
      position: absolute;
      right: 58px;
      top: 50px;
      @include pc-narrow {
        display: none;
        width: 100%;
        height: 100vh;
        position: fixed;
        right: auto;
        left: 0;
        top: 0;
        z-index: 8888;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }
    }
    &-main {
      display: flex;
      transition: all ease-in-out $duration;
      body.is-scrolled.is-pc & {
        top: 35px;
      }
      @include pc-narrow {
        display: block;
        width: 100%;
        height: auto;
        transition: none;
        padding-bottom: 68px;
        &::before {
          content:"";
          background: rgba(#000,0.6);
          width: 100%;
          height: 100%;
          position: fixed;
          left: 0;
          top: 0;
          z-index: 1;
        }
      }
      li {
        margin-left: 50px;
        position: relative;
        &:first-child {
          a {
            border-top: none;
          }
        }
        // &:last-child {
        //     margin-bottom: 68px;
        //     border-bottom: 1px solid #fff;
        // }
        .new {
          position: absolute;
          right: -20px;
          top: -20px;
          z-index: 1;
          animation-duration: 0.5s;
          animation-name: fade-in;
          img {
            width: 29.95px;
            height: 31.2px;
            animation: spin 4s linear infinite;
          }
          @include pc-narrow {
            left: -40px;
            right: auto;
            top: 50%;
            margin-top: -16px;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        @include hover {
          &.has-subnav {
            > a + .sub-nav{
              display: block;
              a {
                pointer-events: all;
              }
            }
          }
          > a {
            color: $c-black;
            &:after {
              transform: scaleX(1);
            }
          }
        }
        &.has-subnav {
          @include pc-narrow {
            a.pc-in + .sub-nav {
              dd:first-child {
                border-top: none;
              }
            }
          }
        }
        @include pc-narrow {
          margin-left: 0;
          position: relative;
          z-index: 1;
          border-bottom: 1px solid #fff;
        }
        a {
          @include en;
          font-size: 2.2rem;
          line-height: 1;
          color: $c-gray;
          transition: color ease-in-out $duration;
          position: relative;
          &:after {
            content:"";
            background: $c-black;
            width: 100%;
            height: 10px;
            position: absolute;
            left: 0;
            top: 1em;
            transform: scaleX(0);
            transform-origin: left;
            transition: all ease-in-out $duration/2;
          }
          &.active {
            color: $c-black;
            &:after {
              transform: scaleX(1);
            }
          }
          @include hover {
            color: $c-black;
            &:after {
              transform: scaleX(1);
            }
          }
          @include pc-narrow {
            display: block;
            width: 100%;
            color: $c-white;
            text-align: center;
            font-size: 2rem;
            padding: 25px 0 23px;
            &.pc-in {
              display: none;
            }
            &.active {
              color: $c-white;
              &:after {
                display: none;
              }
            }
          }
          .in {
            display: inline-block;
            position: relative;
          }
        }
        .sub-nav {
          display: none;
          padding-top: 5px;
          position: absolute;
          left: 0;
          top: 100%;
          width: 130px;
          animation-duration: 0.5s;
          animation-name: fade-in;
          dd {
            margin-top: 10px;
          }
          a {
            display: inline-block;
            position: relative;
            font-size: 2rem;
            pointer-events: none;
            &::before {
              content:">";
              display: inline-block;
              margin-right: 0.25em;
            }
            &::after {
              content:"";
              width: 100%;
              height: 1px;
              background: $c-black;
              position: absolute;
              left: 0;
              bottom: 3px;
              transform: scaleX(0);
              transform-origin: left;
              transition: all ease-in-out $duration/2;
            }
            div {
              display: inline-block;
            }
            .jp {
              font-size: 1.5rem;
            }
          }
          @include pc-narrow {
            display: block;
            position: static;
            width: 100%;
            padding-top: 0px;
            dd {
              margin-top: 0;
              &:first-child {
                a {
                  border-top: none;
                }
              }
            }
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              text-transform: uppercase;
              text-align: center;
              padding: 25px 0;
              border-top: 1px solid #fff;
              pointer-events: visible;
              &::before {
                display: none;
              }
              div {
                display: flex;
                align-items: center;
              }
              .jp {
                display: inline-block;
                padding-left: 0.5em;
                padding-bottom: 2px;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
  .nav-btn {
    width: 33px;
    height: 26px;
    position: fixed;
    right: 20px;
    top: 21px;
    z-index: 9999;
    cursor: pointer;
    body.is-block & {
      span {
        opacity: 0;
        transform: translateX(5px);
      }
      &::before,
      &::after {
        background: #fff;
        width: 36px;
      }
      &::before {
        transform: translateY(12px) rotate(135deg);
      }
      &::after {
        transform: translateY(-12px) rotate(-135deg);
      }
    }
    &::before,
    &::after,
    span {
      position: relative;
      transition: all ease-in-out $duration;
    }
    span {
      background: $c-gray;
      height: 2px;
      width: 33px;
      opacity: 1;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -1px;
    }
    &::before,
    &::after {
      content:"";
      display: block;
      background: $c-gray;
      height: 2px;
      width: 33px;
      position: absolute;
      left: 0;
    }
    &::before {
      top: 0;
    }
     &::after {
      bottom: 0;
    }
  }
}

/* ----------------------------------------
 Footer
---------------------------------------- */
footer {
  text-align: center;
  .copy {
    @include en;
    font-size: 1rem;
    line-height: 1;
    padding: 60px 20px;
  }
  @include tab {
    .copy {
      padding: 30px 15px;
    }
  }
}

/* ----------------------------------------
 Loading
---------------------------------------- */
.loading {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
}
.loading-bar {
  display: none;
  background: #fff;
  width: 100%;
  height: 130px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  @include tab {
    height: 70px;
  }
  .sk-fading-circle {
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
}
.sk-fading-circle {
  margin: auto;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $c-green;
    border-radius: 100%;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }
  .sk-fading-circle .sk-circle2 {
    transform: rotate(30deg); }
  .sk-fading-circle .sk-circle3 {
    transform: rotate(60deg); }
  .sk-fading-circle .sk-circle4 {
    transform: rotate(90deg); }
  .sk-fading-circle .sk-circle5 {
    transform: rotate(120deg); }
  .sk-fading-circle .sk-circle6 {
    transform: rotate(150deg); }
  .sk-fading-circle .sk-circle7 {
    transform: rotate(180deg); }
  .sk-fading-circle .sk-circle8 {
    transform: rotate(210deg); }
  .sk-fading-circle .sk-circle9 {
    transform: rotate(240deg); }
  .sk-fading-circle .sk-circle10 {
    transform: rotate(270deg); }
  .sk-fading-circle .sk-circle11 {
    transform: rotate(300deg); }
  .sk-fading-circle .sk-circle12 {
    transform: rotate(330deg); }
  .sk-fading-circle .sk-circle2:before {
    animation-delay: -1.1s; }
  .sk-fading-circle .sk-circle3:before {
    animation-delay: -1s; }
  .sk-fading-circle .sk-circle4:before {
    animation-delay: -0.9s; }
  .sk-fading-circle .sk-circle5:before {
    animation-delay: -0.8s; }
  .sk-fading-circle .sk-circle6:before {
    animation-delay: -0.7s; }
  .sk-fading-circle .sk-circle7:before {
    animation-delay: -0.6s; }
  .sk-fading-circle .sk-circle8:before {
    animation-delay: -0.5s; }
  .sk-fading-circle .sk-circle9:before {
    animation-delay: -0.4s; }
  .sk-fading-circle .sk-circle10:before {
    animation-delay: -0.3s; }
  .sk-fading-circle .sk-circle11:before {
    animation-delay: -0.2s; }
  .sk-fading-circle .sk-circle12:before {
    animation-delay: -0.1s; }
@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }