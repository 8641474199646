/* ----------------------------------------
 Animation
---------------------------------------- */

// animation: spin 1.5s linear infinite;
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes flash {
  0%,100% {
    opacity: 1;
  }
  25% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
}

@keyframes MoveDown {
  0% {
    transform: translateY(0);
  }
  49.9% {
    transform: translateY(10px);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes MoveDownInfi {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  74.9% {
    transform: translateY(10px);
    opacity: 0;
  }
  75% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes MoveUp {
  0% {
    transform: translateY(0);
  }
  49.9% {
    transform: translateY(-10px);
    opacity: 0;
  }
  50% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes MoveRight {
  0% {
    transform: translateX(0);
  }
  49.9% {
    transform: translateX(10px);
    opacity: 0;
  }
  50% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes MoveLeft {
  0% {
    transform: translateX(0);
  }
  49.9% {
    transform: translateX(-10px);
    opacity: 0;
  }
  50% {
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes reveal{
  0%{
    transform-origin: left;
    transform: translateZ(0) skew(-37deg) scaleX(0);
  }
  0.0001% {
    transform-origin: left;
  }
  50% {
    transform-origin: left;
    transform: translateZ(0) skew(-37deg) scaleX(1);
  }
  50.0001% {
    transform-origin: right;
  }
  100%{
    transform-origin: right;
    transform: translateZ(0) skew(-37deg) scaleX(0);
  }
}