/* ========================================
 Works Setting
======================================== */
.category {
  color: #000;
  font-size: 1.4rem;
  span {
    display: inline-block;
    &:not(:last-of-type) {
      &::after {
        content:",";
        display: inline;
      }
    }
  }
  @include tab {
    font-size: 1rem;
    line-height: 1.4;
  }
}
.works-block {
  margin-top: 86px;
  &#archive-flyer {
    .works-list {
      margin-top: 80px;
      @include tab {
        margin-top: 40px;
      }
    }
  }
  h3 {
    text-align: center;
  }
  .is-under & {
    margin-top: 0;
  }
  @include tab {
    margin-top: 20px;
    .works-list {
      display: block;
    }
  }
  .is-under & {
    h2 {
      margin-bottom: 30px;
    }
  }
  .works-categorys {
    text-align: center;
    margin-bottom: 80px;
    li {
      display: inline-block;
      margin: 0 20px 10px;
      a {
        @include en;
        font-size: 1.6rem;
        letter-spacing: 0;
        @include hover {
          text-decoration: underline;
        }
      }
    }
    @include tab {
      margin-bottom: 40px;
      li {
        margin: 0 15px 10px;
        a {
          font-size: 1.1rem;
        }
      }
    }
  }
  .works-list {
    display: flex;
    flex-wrap: wrap;
    .ajax-item {
      opacity: 0;
      transition: opacity ease-in-out $duration 0.3s;
      &.is-show {
        opacity: 1;
      }
    }
    a {
      display: block;
      width: floor-decimal( (390/1250)*100% );
      margin-left: floor-decimal( (40/1250)*100% );
      margin-bottom: 30px;
      @include hover {
        .figure-wrap {
          .title {
            opacity: 1;
          }
        }
      }
      &:nth-of-type(3n+1) {
        margin-left: 0;
      }
      .figure-wrap {
        position: relative;
        figure {
          width: 100%;
          padding-top: 66.66%;
          position: relative;
          overflow: hidden;
          z-index: 1;
          img {
            width: 100%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%,0);
          }
        }
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(#0000,0.6);
          color: #fff;
          width: 100%;
          height: 100%;
          font-size: 2.2rem;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          opacity: 0;
          transition: all ease-in-out $duration;
        }
      }
      
      @include tab {
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
        &:nth-of-type(3n+1) {
          margin-left: 0;
        }
        .figure-wrap {
          .title {
            display: block;
            background: none;
            color: #000;
            margin-top: 5px;
            position: static;
            font-size: 1rem;
            line-height: 1.4;
            opacity: 1;
          }
        }
      }
    }
  }
  .works-detail {
    overflow: hidden;
    margin-top: 112px;
    @include tab {
      margin-top: 50px;
    }
    &__info {
      float: left;
      width: floor-decimal( (380/1250)*100% );
      @include tab {
        float: none;
        width: 100%;
        .sp-photo {
          img {
            width: 100%;
          }
        }
      }
    }
    &__photo {
      float: right;
      width: floor-decimal( (824/1250)*100% );
      li {
        margin-top: 50px;
        &:first-child {
          margin-top: 0;
        }
        img {
          width: 100%;
        }
      }
      @include tab {
        float: none;
        width: 100%;
        li {
          margin-top: 20px;
        }
      }
    }
    .detail-content {
      @include tab {
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 0 10px;
      }
      a {
        @include hover {
          text-decoration: underline;
        }
      }
      &__title {
        font-size: 2.2rem;
        @include tab {
          font-size: 2.1rem;
          text-align: center;
        }
      }
      &__main {
        margin-top: 45px;
        @include honbun;
        @include tab {
          margin-top: 20px;
        }
      }
      &__info {
        margin-top: 60px;
        padding-top: 10px;
        border-top: 1px solid #000;
        dl {
          overflow: hidden;
          dt {
            float: left;
            width: 80px;
            position: relative;
            &::after {
              content:"：";
              display: inline-block;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          dd {
            overflow: hidden;
          }
        }
        @include tab {
          margin-top: 20px;
        }
      }
    }
  }
  .works-nav {
    margin: 110px 0 136px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 20px 5px 18px;
    overflow: hidden;
    position: relative;
    &.is-flyer {
      width: 100%;
      height: 100px;
      padding: 10px 5px 0;
      border-bottom: none;
      .btn-all {
        position: absolute;
        left: 50%;
        top: calc(50% + 10px);
        transform: translate3d(-50%, -50%,0);
        margin: 0;
      }
      .btn-prev,
      .btn-next {
        display: flex;
        align-items: center;
        figure {
          border: solid 1px #eee;
        }
        img {
          width: 100%;
          max-width: 150px
        }
      }
      .btn-prev {
        flex-direction: row-reverse;
        figure {
          margin-right: 20px;
        }
      }
      .btn-next {
        figure {
          margin-left: 20px;
        }
      }
      @include tab {
        .btn-all {
          top: 10px;
          transform: translate3d(-50%, 0,0);
        }
        .btn-prev,
        .btn-next {
          display: block;
          figure {
            margin: 4px 0 0 0;
          }
          img {
            max-width: 100px;
          }
        }
        .btn-next {
          text-align: right;
        }
      }
    }
    a {
      @include en;
      line-height: 1;
      font-size: 2.4rem;
      &.btn-prev {
        float: left;
      }
      &.btn-next {
        float: right;
      }
      &.btn-all {
        position: absolute;
        left: 50%;
        margin-left: -1.5em;
      }
    }
    @include tab {
      margin: 100px 0;
      a {
        font-size: 1.5rem;
      }
    }
  }
  .btn-area {
    margin-top: 60px;
    text-align: center;
    li {
      &:not(:first-child) {
        margin-top: 50px;
      }
    }
    .btn-works {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 70px;
      margin-left: auto;
      margin-right: auto;
      border: solid 1px #000;
      position: relative;
      &::before {
        content:"";
        background: $c-l-green;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        animation: flash 3s linear infinite;
      }
      span {
        @include en;
        font-size: 3rem;
        z-index: 2;
      }
    }
    .btn-flyer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 420px;
      height: 70px;
      margin-left: auto;
      margin-right: auto;
      border: solid 1px #000;
      position: relative;
      span {
        display: inline-block;
        padding-top: 2px;
        @include en;
        font-size: 2.4rem;
        z-index: 2;
      }
    }
    @include tab {
      li {
        &:not(:first-child) {
          margin-top: 30px;
        }
      }
      .btn-works {
        width: 225px;
        height: 52px;
        span {
          font-size: 2rem;
        }
      }
      .btn-flyer {
        width: 100%;
        max-width: 342px;
        height: 52px;
        span {
          font-size: 2rem;
        }
      }
    }
  }
}