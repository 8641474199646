%svg-common {
	background: url("../img/sprite-bg.svg") no-repeat;
}

.svg-logo {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-logo_dims {
	width: 142.41px;
	height: 160.94px;
}

.svg-scroll {
	@extend %svg-common;
	background-position: 99.85275557425325% 0;
}

.svg-scroll_dims {
	width: 60.38px;
	height: 50.31px;
}

