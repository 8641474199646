@charset "UTF-8";

// ----------------------------------------
// ios.js読み込み時の100vh設定
// height: calc(var(--vh, 1vh) * 100);

// 文字詰設定
// font-feature-settings: "palt";

// CSS Transformsで画面がちらつくとき
// -webkit-backface-visibility: hidden;
// backface-visibility: hidden;
// ----------------------------------------


// ----------------------------------------
//  Breakpoint
// ----------------------------------------

$pc-wide: 1366px;
$pc-width:1250px;
$pc-narrow: 960px;
$breakpoint:800px;
$sp: 480px;
$sp-sml: 320px;

@mixin pc-wide {
  @media only screen and (max-width: ($pc-wide)) {
    @content;
  }
}
@mixin pc {
  @media only screen and (max-width: ($pc-width)) {
    @content;
  }
}
@mixin pc-narrow {
  @media only screen and (max-width: ($pc-narrow)) {
    @content;
  }
}
@mixin tab {
  @media only screen and (max-width: ($breakpoint)) {
    @content;
  }
}
@mixin sp {
  @media only screen and (max-width: ($sp)) {
    @content;
  }
}
@mixin sp-sml {
  @media only screen and (max-width: ($sp-sml)) {
    @content;
  }
}

// ----------------------------------------
//  IE
// ----------------------------------------

@mixin ie {
  .ie &, .ie11 &, .edge & {
    @content;
  }
}

@mixin ie-hack {
  @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
    @content;
  }
}

// ----------------------------------------
//  Font
// ----------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&family=Kosugi&display=swap');

// Josefin Sans
// - Extra-light 200
// - Light 300
// - Regular 400
// Kosugi
// - Regular 400

$font-family-base: 'Kosugi', sans-serif;
$font-size-pc: 1.4rem;
$font-size-sp: 1.1rem;
$base-line-height: 1.75;
$base-letter-spacing: 0.1em;

@mixin en {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
}

@mixin mincho {
  font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", YuMincho, "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

// ----------------------------------------
//  Color
// ----------------------------------------

$c-white: #fff;
$c-black: #000;
$c-gray: #666;
$c-l-gray: #f6f6f6;
$c-green: #14b480;
$c-l-green: #afe3c8;

// ----------------------------------------
//  Hover
// ----------------------------------------
@mixin hover {
  @media only screen and (min-width: ($pc-narrow+1)) {
    body.is-pc & {
      &:hover {
        @content;
      }
    }
  }
}

// ----------------------------------------
//  Border-radius
// ----------------------------------------

$border-radius: 8px;
@mixin radius {
  border-radius: 7px;
  @media (max-width: ($tab)) {
    border-radius: 5px;
  }
}

// ----------------------------------------
//  Animation
// ----------------------------------------

$duration: .3s;

