@charset "utf-8";

// ========================================
//  Foundation
// ========================================
@import "base/_mixins.scss";
@import "base/_reset.scss";

// ----------------------------------------
//  Base
// ----------------------------------------
@import "base/_setting.scss";
@import "base/_base.scss";

// ----------------------------------------
//  MAIN
// ----------------------------------------
@import "parts/_common.scss";
@import "parts/_index.scss";
@import "parts/_works.scss";
@import "parts/_about.scss";
@import "parts/_memo.scss";
@import "parts/_news.scss";

// ----------------------------------------
//  Sprite SVG
// ----------------------------------------
/* ---------- Sprite SVG START ---------- */
@import "_sprite.scss";
/* ---------- Sprite SVG END ---------- */

// ----------------------------------------
//  ANIMATION
// ----------------------------------------
@import "parts/_animation.scss";