/* ========================================
 About Setting
======================================== */

.about-block {
  .is-under & {
    h2 {
      margin-bottom: 30px;
    }
  }
  .about-contents {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @include tab {
      padding: 0 10px;
    }
    h3 {
      line-height: 1;
      margin-bottom: 30px;
    }
    &__block {
      padding-bottom: 80px;
      &-in {
        width: 100%;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
      }
      @include tab {
        padding-bottom: 40px;
      }
      .title {
        margin-bottom: 30px;
        font-size: 2.8rem;
        @include tab {
          margin-bottom: 20px;
          font-size: 2.1rem;
          line-height: 1.38;
        }
      }
      .photo-set {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 120px;
        li {
          width: 100%;
          max-width: 300px;
          margin: 0 14px;
          img {
            width: 100%;
          }
        }
        @include tab {
          margin-top: 20px;
          margin-bottom: 60px;
          li {
            width: 49%;
            max-width: none;
            margin: 0 0 0 2%;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
      .about-information {
        margin-top: 80px;
        dl {
          overflow: hidden;
          font-size: 1.6rem;
          letter-spacing: 0.05em;
          line-height: 1.5;
          dt {
            float: left;
            width: 85px;
            position: relative;
            &::after {
              content:"：";
              display: inline-block;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          dd {
            margin-bottom: 10px;
            overflow: hidden;
            .note {
              display: inline-block;
              margin-bottom: 10px;
              font-size: 1.2rem;
              line-height: 1.5;
            }
          }
        }
        @include tab {
          margin-top: 20px;
          dl {
            font-size: 1.1rem;
            dt {
              width: 60px;
            }
            dd {
              margin-bottom: 20px;
              .note {
                padding-top: 5px;
                margin-bottom: 5px;
                font-size: 1rem;
              }
            }
          }
        }
      }
      .map-wrap {
        margin-top: 40px;
        p {
          font-size: 1.6rem;
          .note {
            font-size: 1.2rem;
            a {
              text-decoration: underline;
            }
          }
        }
        iframe {
          width: 100%;
        }
        @include tab {
          margin-top: 20px;
          p {
            font-size: 1.1rem;
            .note {
              font-size: 1rem;
            }
          }
          iframe {
            height: 224px;
          }
        }
      }
      .profiles {
        margin-bottom: 50px;
        li {
          display: flex;
          align-items: center;
          margin-top: 40px;
          &:first-child {
            margin-top: 0;
          }
          &.photo-change {
            @include hover {
              .profiles__photo {
                .on {
                  opacity: 1;
                }
                .off {
                  opacity: 0;
                }
              }
            }
          }
        }
        @include tab {
          margin-top: 30px;
          li {
            display: block;
          }
        }
        &__photo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 180px;
          min-height: 150px;
          position: relative;
          text-align: center;
          .on,.off {
            transition: all ease-in-out $duration;
          }
          .on {
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
          }
          .illust1 {max-width: 73px;}
          .illust2 {max-width: 92px;}
          .illust3 {max-width: 76px;}
          @include tab {
            width: 100%;
            min-height: 75px;
            &.is-view {
              .on {
                opacity: 1;
              }
              .off {
                opacity: 0;
              }
            }
            .on {
              max-width: 75px;
            }
            .illust1 {max-width: 39px;}
            .illust2 {max-width: 56px;}
            .illust3 {max-width: 38px;}
          }
        }
        &__info {
          width: calc(100% - 180px);
          .degree {
            font-size: 1.6rem;
          }
          .name {
            font-size: 1.6rem;
            .en {
              @include en;
            }
          }
          .profile {
            font-size: 1.2rem;
            letter-spacing: 0.05em;
          }
          @include tab {
            width: 100%;
            margin-top: 20px;
            .degree {
              font-size: 1.5rem;
              text-align: center;
            }
            .name {
              font-size: 1.5rem;
              text-align: center;
            }
            .profile {
              margin-top: 10px;
              font-size: 1.1rem;
            }
          }
        }
      }
      .memo-btns {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          width: 300px;
          height: 100px;
          margin: 0 25px;
          border: solid 1px #000;
          font-size: 2rem;
          transition: all ease-in-out $duration;
          @include hover {
            background: $c-l-green;
          }
        }
        @include tab {
          display: block;
          a {
            width: 225px;
            height: 54px;
            margin: 26px auto 0;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}