/* ========================================
 Memo Setting
======================================== */
.memo-block {
  margin-bottom: 80px;
  @include tab {
    margin-bottom: 40px;
  }
  .memo-contents{
    width: 100%;
    max-width: 836px;
    margin-left: auto;
    margin-right: auto;
    h3 {
      font-size: 2.8rem;
      margin-bottom: 0;
      padding: 0 43px;
    }
    h4 {
      font-size: 2.8rem;
      margin-bottom: 15px;
      line-height: 1.5;
    }
    @include tab {
      h3 {
        font-size: 1.8rem;
      }
      h4 {
        font-size: 2.1rem;
        line-height: 1.38;
      }
    }
    &__block {
      margin-top: 40px;
      &#life {
        margin-top: 100px;
      }
      @include tab {
        margin-top: 20px;
        padding-top: 20px;
        &#life {
          margin-top: 80px;
        }
      }
    }
    .acc-content {
      background: $c-l-gray;
      margin-top: 58px;
      padding: 30px 43px 50px;
      position: relative;
      @include tab {
        margin-top: 36px;
        padding: 17px;
      }
      h4 {
        margin-top: 0;
        @include tab {
          letter-spacing: 0;
        }
      }
      .acc-main {
        position: relative;
        overflow: hidden;
        height: (1.6 * 2.5 * 2)+rem;  // 文字サイズ*行間*表示したい行数
        text-align: justify;
        text-justify: inter-ideograph;
        font-size: 1.6rem;
        @include tab {
          font-size: 1.1rem;
        }
        &::after {
          content:"...続きを読む";
          background: $c-l-gray;
          color: #808080;
          line-height: 1;
          padding-left: 0.5em;
          position: absolute;
          right: 0;
          bottom: (2.5 - 1.75)+em;
          transition: all ease-in-out $duration;
        }
        &.is-open {
          &::after {
            opacity: 0;
          }
        }
        @include tab {
          height: (1.1 * 2.5 * 2)+rem;
        }
      }
      .btn-open {
        background: #fff;
        border-radius: 5px;
        width: 52px;
        height: 60px;
        @include en;
        font-size: 1.2rem;
        line-height: 1;
        text-align: center;
        padding-top: 42px;
        position: absolute;
        left: 50%;
        bottom: -40px;
        z-index: 1;
        margin-left: -26px;
        cursor: pointer;
        &::before,
        &::after {
          content:"";
          background: #000;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        &::before {
          width: 28px;
          height: 2px;
          top: 23px;
        }
        &::after {
          width: 2px;
          height: 28px;
          top: 10px;
        }
        &.is-open {
          &::after {
            display: none;
          }
          .open {
            display: none;
          }
          .close {
            display: block;
          }
        }
        .close {
          display: none;
        }
        @include tab {
          transform: scale(0.5) translateY(-20%);
          &::before {
            width: 26px;
            top: 22px;
          }
          &::after {
            width: 2px;
            height: 26px;
            top: 10px;
          }
        }
      }
    }
    .life-archives {
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
      .life-archive {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        color: #000;
        &__photo {
          width: floor-decimal( (240/750)*100% );
          img {
            width: 100%;
          }
        }
        &__text {
          width: floor-decimal( (490/750)*100% );
          h4 {
            margin-bottom: 20px;
          }
        }
        @include tab {
          margin-top: 20px;
          h4 {
            margin-bottom: 10px;
          }
          p {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}