@charset "utf-8";

// ----------------------------------------
//  mixin.css
// ----------------------------------------

// Easing
// ---------------------------------------- //

$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);

$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);

$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);

// 縦書き
@mixin writing-mode( $direction )
{
  -ms-writing-mode: tb- + $direction;               /* IE用　*/
  -webkit-writing-mode: vertical- + $direction;     /* chrome用　*/
  -moz-writing-mode: vertical- + $direction;        /* Firefox用　*/
  -o-writing-mode: vertical- + $direction;          /* opera用　*/
  writing-mode: vertical- + $direction;       /* その他 */
}

// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
// 例）@include fz_vw(50, 1280);
@function get_vw($size, $viewport:320){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:10, $breakpoint:1000){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size,$breakpoint);
}

// display: flex
// ---------------------------------------- //
@mixin flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// flex-direction
// - row  : row
// - rowR : row-reverse
// - col  : column
// - colR : column-reverse
@mixin flex-direction($value: row) {
  @if $value==rowR {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  @else if $value==col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  @else if $value==colR {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  @else {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

// flex-wrap（基本：nowrap）
// - wrap
@mixin flex-wrap($value: nowrap) {
  @if $value==wrap {
    flex-wrap: $value;
    -ms-flex-wrap: $value;
    -webkit-flex-wrap: $value;
  }
  @else {
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
  }
}

// justify-content（基本：start）
// - sb : space-between
// - sa : space-around
// - center
@mixin justify-content($value: start) {
  @if $value==sb {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  @else if $value==sa {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  @else if $value==center {
    justify-content: center;
    -ms-justify-content: center;
    -webkit-justify-content: center;
  }
  @else {
    justify-content: start;
    -ms-justify-content: start;
    -webkit-justify-content: start;
  }
}

// align-items（基本：stretch）
// - start
// - end
// - center
// - baseline
@mixin align-items($value: stretch) {
  @if $value==start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  @else if $value==end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  @else if $value==center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  @else if $value==baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  @else {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

// align-content（基本：stretch）
// - start
// - end
// - center
// - sb
// - sa
@mixin align-content($value: stretch) {
  @if $value==start {
    -ms-flex-line-pack: start;
    -webkit-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }
  @else if $value==end {
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }
  @else if $value==center {
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }
  @else if $value==sb {
    -ms-flex-line-pack: justify;
    -webkit-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }
  @else if $value==sa {
    -ms-flex-line-pack: distribute;
    -webkit-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }
  @else {
    -ms-flex-line-pack: stretch;
    -webkit-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }
}

// order（数値）
@mixin order($value) {
  -webkit-box-ordinal-group: $value;
  -ms-flex-order: $value;
  order: $value;
}

// flex-basis（数値）
@mixin flex-basis($value: auto) {
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

// flex-grow（数値）
@mixin flex-grow($value) {
  -webkit-box-flex: $value;
  -ms-flex-positive: $value;
  flex-grow: $value;
}

// align-self（基本：auto）
// - start
// - end
// - baseline
// - stretch
@mixin align-self($value: stretch) {
  @if $value==start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  @else if $value==end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  @else if $value==center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  @else if $value==baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  @else if $value==stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  @else {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
}

// @include keyframes($animation-name) {~~~}
@mixin keyframes($animation-name: animaton) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

// @include calc(width, "100% - 282px");
@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin overflow-scroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

@mixin text-justify {
  text-align: justify;
  -ms-text-justify: inter-cluster;
  text-justify: inter-cluster;
}

@mixin pos-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin bg-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@mixin initList($margin) {
  margin: $margin;
  padding: 0;
  list-style-type: none;
}

@mixin initLink($color) {
  color: $color;
  text-decoration: none;
}

@mixin warpLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent:-9999px;
}

@mixin clearfix {
  zoom: 1;
  &:after {
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
    line-height: 0;
    content: ".";
  }
}

@mixin cssSprite($url) {
  display: block;
  overflow: hidden;
  background-image: url($url);
  background-repeat: no-repeat;
  span {
    visibility: hidden;
  }
}

@mixin spriteRoll($x, $off, $on) {
  background-position: $x $off;
  &:hover {
    background-position: $x $on;
  }
  &.visit, &.active {
    background-position: $x $on;
  }
}

@mixin addIcon($wid, $hei, $x, $y) {
  display: inline-block;
  width: $wid;
  height: $hei;
  content: "";
  vertical-align: middle;
  background-repeat: no-repeat;
  @if $x == false and $y == false {}
  @else { background-position: $x $y; }
  background-size: cover;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin border-top-left-radius($radius) {
  -webkit-border-top-left-radius: $radius;
      -moz-border-radius-topleft: $radius;
          border-top-left-radius: $radius;
}
@mixin border-top-right-radius($radius) {
  -webkit-border-top-right-radius: $radius;
      -moz-border-radius-topright: $radius;
          border-top-right-radius: $radius;
}
@mixin border-bottom-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
      -moz-border-radius-bottomright: $radius;
          border-bottom-right-radius: $radius;
}
@mixin border-bottom-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
      -moz-border-radius-bottomleft: $radius;
          border-bottom-left-radius: $radius;
}


//****************************************
// 関数
//****************************************

// Round (四捨五入)
@function round-decimal ($number, $digits: 0) {
    @return to-fixed($number, $digits, 'round');
}

// Ceil (切り上げ)
@function ceil-decimal ($number, $digits: 0) {
    @return to-fixed($number, $digits, 'ceil');
}

// Floor (切り捨て) 例）floor-decimal( (300/915)*100% )
@function floor-decimal ($number, $digits: 0) {
    @return to-fixed($number, $digits, 'floor');
}

@function to-fixed ($number, $digits: 0, $round: 'round') {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $round == 'round' {
        @return round($number * $n) / $n;
    } @else if $round == 'ceil' {
        @return ceil($number * $n) / $n;
    } @else if $round == 'floor' {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $round } is undefined keyword.';
        @return $number;
    }
}
