/* ========================================
 Index Setting
======================================== */

.mainvisual {
  position: relative;
  .slider {
    .slide {
      figure {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 625px;
        position: relative;
        @include tab {
          height: 375px;
        }
      }
    }
  }
  .scroll {
    position: absolute;
    left: 50%;
    bottom: 20px;
    margin-left: -30px;
    z-index: 1;
    animation: flash 2s linear infinite;
    cursor: pointer;
    svg {
      fill: #fff;
    }
    @include tab {
      position: relative;
      top: 10px;
      bottom: auto;
      margin-left: -15px;
      svg {
        fill: $c-gray;
        width: 29px;
        height: 15px;
      }
    }
  }
}
.news-block {
  margin-top: 100px;
  .news-list {
    width: 100%;
    max-width: ($breakpoint + 40px);
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    dl {
      overflow: hidden;
      dt {
        float: left;
        width: 130px;
      }
      dd {
        overflow: hidden;
        margin-bottom: 12px;
        a {
          transition: all ease-in-out $duration;
          @include hover {
            opacity: 0.7;
          }
        }
      }
    }
    .btn-area {
      margin-top: 10px;
      text-align: right;
      a {
        @include en;
        font-size: 2.2rem;
      }
    }
  }
  @include tab {
    margin-top: 60px;
    .news-list {
      padding-left: 10px;
      padding-right: 10px;
      dl {
        dt {
          float: none;
          width: 100%;
        }
      }
      .btn-area {
        a {
          font-size: 1.3rem;
        }
      }
    }
  }
}