/* ========================================
 News Setting
======================================== */

.pagination {
  margin-top: 50px;
  text-align: center;
  a,span {
    display: inline-block;
    @include en;
    font-size: 2rem;
    padding: 6px 8px;
  }
  a {
    color: #808080;
    text-decoration: underline;
    &.prev ,
    &.next {
      text-decoration: none;
    }
  }
}

.news-block {
  width: 100%;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  .is-under & {
    margin-top: 40px;
  }
  @include tab {
    padding: 0 10px;
  }
  &__thumbnail {
    margin-bottom: 20px;
    text-align: center;
    img {
      width: 100%;
      max-width: 750px;
    }
  }
  &__meta {
    .date {
      font-family: 1.4rem;
    }
  }
  &__title {
    margin-top: 10px;
    font-size: 2.8rem;
    @include tab {
      font-size: 2.1rem;
    }
  }
  &__content {
    margin-top: 40px;
    font-size: 1.6rem;
    overflow: hidden;
    @include tab {
      margin-top: 20px;
    }
    p {
      margin-bottom: 1em;
      @extend .base-text;
    }
    img {
      width: 100%;
      height: auto;
    }
    .is-postimage {
      display: inline-block;
      margin-top: 40px;
      margin-bottom: 40px;
      max-width: 100%;
      height: auto;
    }
    .aligncenter {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
    .alignright {
      float: right;
    }
    .alignleft {
      float: left;
    }
  }
}